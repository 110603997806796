"use client";

import { useCallback } from "react";
import { api } from "./api";
import { ApiRequestInit } from "./api";

export interface UseApiReturn {
  get: <T>(path: string, init?: ApiRequestInit) => Promise<T>;
  post: <T>(path: string, data?: any, init?: ApiRequestInit) => Promise<T>;
  put: <T>(path: string, data?: any, init?: ApiRequestInit) => Promise<T>;
  delete: <T>(path: string, init?: ApiRequestInit) => Promise<T>;
  patch: <T>(path: string, data?: any, init?: ApiRequestInit) => Promise<T>;
}

export function useApi(): UseApiReturn {
  const get = useCallback(
    <T>(path: string, init?: ApiRequestInit) => api.get<T>(path, init),
    []
  );

  const post = useCallback(
    <T>(path: string, data?: any, init?: ApiRequestInit) =>
      api.post<T>(path, data, init),
    []
  );

  const put = useCallback(
    <T>(path: string, data?: any, init?: ApiRequestInit) =>
      api.put<T>(path, data, init),
    []
  );

  const del = useCallback(
    <T>(path: string, init?: ApiRequestInit) => api.delete<T>(path, init),
    []
  );

  const patch = useCallback(
    <T>(path: string, data?: any, init?: ApiRequestInit) =>
      api.patch<T>(path, data, init),
    []
  );

  return {
    get,
    post,
    put,
    delete: del,
    patch,
  };
}
