"use client";

import { handleSignIn } from "@/features/auth/actions/auth-actions";
import { usePathname, useSearchParams } from "next/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

export function SignInButton() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const handleClick = () => {
    // Create the full current path including search params
    let currentPath = pathname;
    const searchString = searchParams.toString();
    if (searchString) {
      currentPath += `?${searchString}`;
    }

    // Call handleSignIn with the current path as returnTo
    handleSignIn(currentPath);
  };

  return (
    <button
      onClick={handleClick}
      className="flex items-center px-4 py-1.5 text-sm font-medium text-white border border-white/30 hover:bg-white/10 rounded-md transition-all focus:outline-none focus:ring-2 focus:ring-white/50"
    >
      <FontAwesomeIcon icon={faSignInAlt} className="mr-2 h-3.5 w-3.5" />
      Sign In
    </button>
  );
}
