"use client";

import { useAuth } from "@/features/auth/context/auth-context";
import { SignInButton } from "@/features/auth/components/sign-in-button";
import { UserDropdown } from "@/features/auth/components/user-dropdown";

export function LoginStatusClient() {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="h-8 w-8 animate-pulse bg-white/20 rounded-full"></div>
    );
  }

  if (isAuthenticated) {
    return <UserDropdown />;
  }

  return <SignInButton />;
}
