import {
  getAuthState,
  refreshAccessToken,
} from "@/features/auth/actions/auth-actions";

// Types
export interface ApiError extends Error {
  status: number;
  statusText: string;
  errors: { message: string; propertyPath?: string }[];
  data?: any;
  path?: string;
  method?: string;
  timestamp?: string;
}

export interface ApiRequestInit extends RequestInit {
  authenticated?: boolean;
  body?: any;
}

export interface ApiResponse<T = any> {
  data: T;
  status: number;
  headers: Headers;
}

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

/**
 * Format and normalize API errors to a consistent structure
 */
function formatApiError(error: unknown, response?: Response): ApiError {
  // If it's already an ApiError, return it
  if (error instanceof Error && "errors" in error && "status" in error) {
    return error as ApiError;
  }

  const apiError = new Error() as ApiError;

  // If we have a response, use its status
  if (response) {
    apiError.status = response.status;
    apiError.statusText = response.statusText;
    apiError.message = `API Error: ${response.status} ${response.statusText}`;
  } else {
    apiError.status = 500;
    apiError.statusText = "Internal Server Error";
    apiError.message = "An unknown error occurred";
  }

  // Default error message
  apiError.errors = [{ message: apiError.message }];

  // Try to extract more information if it's an Error
  if (error instanceof Error) {
    apiError.message = error.message;
    apiError.stack = error.stack;
    apiError.errors = [{ message: error.message }];

    // Preserve any additional properties that might exist on the error
    Object.entries(error).forEach(([key, value]) => {
      if (
        !["message", "stack", "errors", "status", "statusText"].includes(key)
      ) {
        (apiError as any)[key] = value;
      }
    });
  }

  return apiError;
}

/**
 * Base API Client implementation
 * Handles basic HTTP operations without authentication concerns
 */
class BaseApiClient {
  constructor(private baseUrl: string, private useProxy: boolean = true) {}

  /**
   * Make a request to the API
   */
  public async request<T>(
    method: HttpMethod,
    path: string,
    init?: ApiRequestInit
  ): Promise<ApiResponse<T>> {
    try {
      // Determine if we should use the proxy or direct API call
      const url = this.useProxy
        ? this.resolveProxyUrl(path, init?.authenticated)
        : this.resolveDirectUrl(path);

      const headers = new Headers();

      // Add content type for JSON requests
      headers.set("Content-Type", "application/json");

      if (this.useProxy && init?.authenticated) {
        // Use custom header to tell proxy that auth is required
        headers.set("x-auth-required", "true");
      }

      // Merge custom headers
      if (init?.headers) {
        if (init.headers instanceof Headers) {
          init.headers.forEach((value, key) => {
            headers.set(key, value);
          });
        } else {
          Object.entries(init.headers).forEach(([key, value]) => {
            if (value !== undefined) {
              headers.set(key, String(value));
            }
          });
        }
      }

      // Prepare request options
      const fetchOptions: RequestInit = {
        ...init,
        method,
        headers,
      };

      // When using proxy, we don't need credentials as the proxy will handle that
      // We only include credentials when using direct API calls
      if (!this.useProxy) {
        fetchOptions.credentials = "include"; // For direct API calls, include cookies
      }

      // Handle the body
      if (init?.body !== undefined) {
        if (typeof init.body === "string") {
          fetchOptions.body = init.body;
        } else if (init.body !== null) {
          fetchOptions.body = JSON.stringify(init.body);
        }
      }

      // Make the actual request
      const response = await fetch(url, fetchOptions);

      // Check for token refresh header
      if (response.headers.get("x-token-refreshed") === "true") {
        // Token was refreshed, we could notify the user or perform additional actions
        console.info("Access token was refreshed");

        // Call the refresh endpoint to update cookies if we're in the browser
        if (typeof window !== "undefined") {
          try {
            // Use POST to refresh endpoint to update the cookies with proper error handling
            fetch("/api/auth/refresh", {
              method: "POST",
              headers: { "Cache-Control": "no-cache" },
            })
              .then((res) => {
                if (!res.ok) {
                  console.warn(
                    `Failed to update refresh token cookies: ${res.status} ${res.statusText}`
                  );
                  // If refresh endpoint returns 401, user needs to be logged out
                  if (res.status === 401) {
                    console.warn(
                      "Authentication failed during refresh, redirecting to logout"
                    );
                    window.location.href = "/api/auth/logout";
                  }
                }
              })
              .catch((err) => {
                console.error("Error updating refresh token cookies:", err);
              });
          } catch (error) {
            console.error("Failed to trigger cookie refresh:", error);
          }
        }
      }

      // Check if logout is required due to authentication failure
      if (response.headers.get("x-auth-logout-required") === "true") {
        // Handle logout - redirect to logout endpoint
        if (typeof window !== "undefined") {
          console.warn("Authentication failed, redirecting to logout");
          window.location.href = "/api/auth/logout";
          // Return a promise that never resolves since we're navigating away
          return new Promise(() => {});
        }
      }

      let data: any;
      const contentType = response.headers.get("content-type");

      // Parse the response
      if (contentType && contentType.includes("application/json")) {
        data = await response.json();
      } else {
        data = await response.text();
      }

      if (!response.ok) {
        const error = formatApiError(null, response);

        // Extract error details
        if (typeof data === "object" && data !== null) {
          error.data = data;
          if (data.errors && Array.isArray(data.errors)) {
            error.errors = data.errors;
          } else if (
            data.error &&
            typeof data.error === "object" &&
            data.error.errors
          ) {
            error.errors = data.error.errors;
          } else if (data.message) {
            error.errors = [{ message: data.message }];
            // Include original stack for better debugging in non-prod environments
            if (data.stack && process.env.NODE_ENV !== "production") {
              error.stack = data.stack;
            }
          } else if (data.error && typeof data.error === "string") {
            error.errors = [{ message: data.error }];
          }

          // Include any additional error context from the proxy
          if (data.path) error.path = data.path;
          if (data.method) error.method = data.method;
          if (data.timestamp) error.timestamp = data.timestamp;
        } else if (typeof data === "string") {
          error.data = data;
          error.errors = [{ message: data || "Unknown error" }];
        }

        throw error;
      }

      return {
        data,
        status: response.status,
        headers: response.headers,
      };
    } catch (error) {
      // If the error is already an ApiError, rethrow it
      if (error instanceof Error && "status" in error && "errors" in error) {
        throw error;
      }

      // Otherwise, format and throw a proper ApiError
      throw formatApiError(error);
    }
  }

  /**
   * Resolve a path to a proxy URL with query parameters
   */
  private resolveProxyUrl(path: string, authenticated?: boolean): string {
    // Handle path with existing query parameters
    const [pathWithoutQuery, existingQuery] = path.split("?");

    // Normalize the path (remove leading slash)
    const normalizedPath = pathWithoutQuery.startsWith("/")
      ? pathWithoutQuery.slice(1)
      : pathWithoutQuery;

    const baseUrl =
      typeof window !== "undefined"
        ? window.location.origin
        : process.env.FOOTYINFO_WEB_URL;

    // Create URL with path segments for the [...path] route pattern
    let proxyUrl = `${baseUrl}/api/proxy/${normalizedPath}`;

    // Add back any query parameters that were in the original URL
    if (existingQuery) {
      proxyUrl += `?${existingQuery}`;
    }

    // Add authenticated param if needed (as fallback for clients that can't set headers)
    if (authenticated && !existingQuery) {
      proxyUrl += "?authenticated=true";
    } else if (authenticated && existingQuery) {
      proxyUrl += "&authenticated=true";
    }

    return proxyUrl;
  }

  /**
   * Resolve a relative path to a direct API URL
   */
  private resolveDirectUrl(path: string): string {
    const baseUrl = this.baseUrl.endsWith("/")
      ? this.baseUrl.slice(0, -1)
      : this.baseUrl;
    const normalizedPath = path.startsWith("/") ? path.slice(1) : path;
    return `${baseUrl}/${normalizedPath}`;
  }

  // Convenience methods for different HTTP methods
  async get<T>(path: string, init?: ApiRequestInit): Promise<ApiResponse<T>> {
    return this.request<T>("GET", path, init);
  }

  async post<T>(
    path: string,
    data?: any,
    init?: ApiRequestInit
  ): Promise<ApiResponse<T>> {
    return this.request<T>("POST", path, {
      ...init,
      body: data,
    });
  }

  async put<T>(
    path: string,
    data?: any,
    init?: ApiRequestInit
  ): Promise<ApiResponse<T>> {
    return this.request<T>("PUT", path, {
      ...init,
      body: data,
    });
  }

  async delete<T>(
    path: string,
    init?: ApiRequestInit
  ): Promise<ApiResponse<T>> {
    return this.request<T>("DELETE", path, init);
  }

  async patch<T>(
    path: string,
    data?: any,
    init?: ApiRequestInit
  ): Promise<ApiResponse<T>> {
    return this.request<T>("PATCH", path, {
      ...init,
      body: data,
    });
  }
}

/**
 * API URLs for server and client environments
 */
const API_URL = {
  server: process.env.FOOTYINFO_API_URL ?? "",
  client: process.env.NEXT_PUBLIC_FOOTYINFO_API_URL ?? "",
};

/**
 * Server-side API client with authentication
 */
class ServerApiClient extends BaseApiClient {
  constructor() {
    super(API_URL.server, true); // Always use proxy for consistency
  }

  /**
   * Make an authenticated request with token refresh handling
   */
  public async request<T>(
    method: HttpMethod,
    path: string,
    init?: ApiRequestInit
  ): Promise<ApiResponse<T>> {
    // If authentication is explicitly disabled, use unauthenticated request
    if (init?.authenticated === false) {
      return super.request<T>(method, path, init);
    }

    // Otherwise, all server requests are authenticated by default
    return super.request<T>(method, path, {
      ...init,
      authenticated: true,
    });
  }
}

/**
 * Client-side API client with authentication
 */
class ClientApiClient extends BaseApiClient {
  constructor() {
    super(API_URL.client, true); // Always use proxy for consistency
  }

  /**
   * Make an authenticated request with token refresh handling
   */
  public async request<T>(
    method: HttpMethod,
    path: string,
    init?: ApiRequestInit
  ): Promise<ApiResponse<T>> {
    // If authentication is explicitly disabled, use unauthenticated request
    if (init?.authenticated === false) {
      return super.request<T>(method, path, init);
    }

    // Otherwise, all client requests are authenticated by default
    return super.request<T>(method, path, {
      ...init,
      authenticated: true,
    });
  }
}

/**
 * Isomorphic API client that works in both browser and server environments
 */
class IsomorphicApiClient {
  private static instance: IsomorphicApiClient;
  private apiClient: BaseApiClient;

  private constructor() {
    this.apiClient =
      typeof window === "undefined"
        ? new ServerApiClient()
        : new ClientApiClient();
  }

  /**
   * Get the singleton instance
   */
  public static getInstance(): IsomorphicApiClient {
    if (!IsomorphicApiClient.instance) {
      IsomorphicApiClient.instance = new IsomorphicApiClient();
    }

    return IsomorphicApiClient.instance;
  }

  // Simplified interface that returns just the data
  async get<T>(path: string, init?: ApiRequestInit): Promise<T> {
    const response = await this.apiClient.get<T>(path, init);
    return response.data;
  }

  async post<T>(path: string, data?: any, init?: ApiRequestInit): Promise<T> {
    const response = await this.apiClient.post<T>(path, data, init);
    return response.data;
  }

  async put<T>(path: string, data?: any, init?: ApiRequestInit): Promise<T> {
    const response = await this.apiClient.put<T>(path, data, init);
    return response.data;
  }

  async delete<T>(path: string, init?: ApiRequestInit): Promise<T> {
    const response = await this.apiClient.delete<T>(path, init);
    return response.data;
  }

  async patch<T>(path: string, data?: any, init?: ApiRequestInit): Promise<T> {
    const response = await this.apiClient.patch<T>(path, data, init);
    return response.data;
  }

  // Direct access to the response for when you need headers/status
  async request<T>(
    method: HttpMethod,
    path: string,
    init?: ApiRequestInit
  ): Promise<ApiResponse<T>> {
    return this.apiClient.request<T>(method, path, init);
  }
}

// Export the singleton instance
export const api = IsomorphicApiClient.getInstance();
