"use client";

import { useState, useRef, useEffect } from "react";
import { useAuth } from "@/features/auth/context/auth-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faChevronDown,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/navigation";

export function UserDropdown() {
  const { screenName, email, isLoading, refreshAuth } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isClientReady, setIsClientReady] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const displayName = screenName || email?.split("@")[0] || "User";

  // Toggle dropdown function
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle logout via the API route that handles FusionAuth session termination
  const handleLogout = () => {
    setIsOpen(false);
    router.push("/api/auth/logout");
  };

  // Handle client-side hydration
  useEffect(() => {
    setIsClientReady(true);
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Only show the component after client-side hydration is complete
  if (!isClientReady) {
    return (
      <div className="flex items-center justify-between px-4 py-1.5 text-sm font-medium text-white border border-white/30 rounded-md min-w-[110px] opacity-80">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faUser} className="h-3.5 w-3.5" />
          <div className="w-16 h-4 bg-white/20 rounded"></div>
        </div>
        <FontAwesomeIcon icon={faChevronDown} className="ml-2 h-3.5 w-3.5" />
      </div>
    );
  }

  // Show loading state
  if (isLoading) {
    return (
      <div className="flex items-center justify-between px-4 py-1.5 text-sm font-medium text-white border border-white/30 rounded-md min-w-[110px] opacity-80">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faUser} className="h-3.5 w-3.5" />
          <div className="w-16 h-4 bg-white/20 animate-pulse rounded"></div>
        </div>
        <FontAwesomeIcon icon={faChevronDown} className="ml-2 h-3.5 w-3.5" />
      </div>
    );
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-between px-4 py-1.5 text-sm font-medium text-white border border-white/30 hover:bg-white/10 rounded-md transition-all focus:outline-none focus:ring-2 focus:ring-white/50 min-w-[110px]"
      >
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faUser} className="h-3.5 w-3.5" />
          <span className="truncate max-w-[120px]">{displayName}</span>
        </div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={`ml-2 h-3.5 w-3.5 transition-transform ${
            isOpen ? "transform rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg py-1 z-10 border border-gray-200">
          <div className="px-4 py-2 border-b border-gray-100">
            <div className="font-medium text-sm text-gray-800 truncate">
              {displayName}
            </div>
            {email && (
              <div className="text-xs text-gray-500 truncate mt-0.5">
                {email}
              </div>
            )}
          </div>

          <button
            onClick={handleLogout}
            className="flex items-center w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
          >
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className="mr-2 h-4 w-4 text-gray-500"
            />
            Sign Out
          </button>
        </div>
      )}
    </div>
  );
}
